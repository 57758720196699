import React, { ChangeEvent, EventHandler, FormEvent } from 'react';

import Button from '../../library/button/button';
import Select from '../../library/select/select';
import Text from '../../library/text/text';
import Typography from '../../library/typography/typography';

import { SEARCH_RADIUS_OPTIONS } from '../../shared/constants/search-types';

import './find-store.scss';

interface FindStoreProps {
  hideSearchButton?: boolean;
  hideTitle?: boolean;
  isCheckInventory?: boolean;
  isSearching?: boolean;
  onBlur?: EventHandler<ChangeEvent<HTMLInputElement>>;
  onFilterChange?: () => void;
  onRadiusChange: (arg: { label: string; value: number }) => void;
  onSearchTermChange: EventHandler<ChangeEvent<HTMLInputElement>>;
  onSubmit?: EventHandler<FormEvent<HTMLFormElement>>;
  radius: number;
  searchTerm: string;
  searchTermLabel?: string;
  shouldFilterByPitPass?: boolean;
  showSubLabel?: boolean;
}

function FindStore(props: FindStoreProps) {
  const {
    isSearching,
    hideSearchButton = false,
    hideTitle = false,
    onRadiusChange,
    onSearchTermChange,
    onBlur,
    onSubmit,
    radius,
    searchTerm,
    searchTermLabel,
    showSubLabel = false
  } = props;

  return (
    <form onSubmit={onSubmit} styleName="container">
      {!hideTitle && !showSubLabel && (
        <label htmlFor="searchTerm" styleName="title">
          Find A Store
        </label>
      )}
      <div styleName="controls">
        <div className="find-store__search-input-container">
          <Text
            className="find-store__search-input"
            id="searchTerm"
            inputMode="search"
            isRequired
            label={
              searchTermLabel ? searchTermLabel : 'City, State, or Zip Code'
            }
            onBlur={onBlur}
            onChange={onSearchTermChange}
            value={searchTerm}
          />

          {showSubLabel && (
            <Typography
              htmlFor="searchTerm"
              size="small"
              styleName="label"
              tag="label"
            >
              Pricing may vary by region
            </Typography>
          )}
        </div>

        <Select
          className="find-store__search-radius"
          id="radius"
          label="Search Radius"
          onChange={onRadiusChange}
          options={SEARCH_RADIUS_OPTIONS}
          value={SEARCH_RADIUS_OPTIONS.find(r => r.value === radius)}
        />

        <Button
          className={`find-store__button${
            hideSearchButton ? ' display-none' : ''
          }`}
          isDisabled={!searchTerm}
          isPrimary
        >
          {isSearching && searchTerm ? 'Searching' : 'Search'}
        </Button>
      </div>
    </form>
  );
}

export default FindStore;
